<template>
  <LoginForm
    :is-loading="isLoginControlLoading"
    :login-error="loginError"
    :server-error="serverError"
    @login="login"
  />
</template>

<script>
// Vuex
import { mapGetters, mapActions } from 'vuex';

// Components
import LoginForm from '~/components/Auth/LoginForm';

// constants
import { RELOAD_TRIGGER } from '~/assets/js/constants';

export default {
  name: 'LoginPage',

  layout: 'auth',

  middleware({ store, redirect }) {
    const isLoggedIn = Boolean(store.state.general.token);
    return !isLoggedIn || redirect('/');
  },

  components: {
    LoginForm,
  },

  data: () => ({
    loginError: null,
  }),

  computed: {
    ...mapGetters({
      isLoginControlLoading: 'auth/login/GET_CONTROL_LOADING',
      serverError: 'general/GET_ERROR_MSG',
    }),
  },

  methods: {
    ...mapActions({
      logIn: 'auth/login/LOG_IN',
      setAuthCookie: 'general/SET_AUTH_COOKIE',
      setAuthHeader: 'general/SET_AUTH_HEADER',
      removeAuthCookie: 'general/REMOVE_AUTH_COOKIE',
      removeAuthHeader: 'general/REMOVE_AUTH_HEADER',
      getUserInfo: 'main/getInfo',
    }),

    async login(params) {
      try {
        const { token } = await this.logIn({ ...params });

        await this.setAuthCookie(token);
        await this.setAuthHeader(token);
        await this.getUserInfo();
        await this.$router.push('/');

        localStorage.setItem(RELOAD_TRIGGER, String(new Date().valueOf()));
      } catch (error) {
        if (!this.serverError) {
          this.loginError = error;
        }
        this.removeAuthCookie();
        this.removeAuthHeader();
      }
    },
  },

  head() {
    return {
      title: `${this.$t('auth.Log in')} - All Trucks Online`,
    };
  },
};
</script>
